body {
  font-family: "Noto Sans";
  color: #050505;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #fbab7e;
  background-image: linear-gradient(
    45deg,
    #e3f2fd 0%,   /* Light blue */
    #bbdefb 50%,  /* Medium blue */
    #90caf9 100%  /* Darker blue */
  );
  overflow: hidden;
}

.list-wrapper {
  position: relative;
  width: 400px;
  padding: 3rem 4rem;
  border-radius: 5px;
}

h1 {
  text-align: left;
  font-size: 1.4rem;
  font-weight: 400;
}

.styled-list {
  list-style-position: inside;
  position: relative;
  /* width: 320px; */
  padding: 1.2rem;
  line-height: 1.5rem;
  background: white;
  border-radius: 5px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

li {
  position: relative;
  list-style: none;
}

label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

label:hover .todo-text {
  text-decoration: line-through;
  color: #a9a9a9;
}

input[type="checkbox"]:checked ~ .todo-text {
  text-decoration: line-through;
  color: #ccc;
}
label:hover .todo-remove {
  opacity: 1; /* Make the X button visible on hover */
}
.todo-remove {
  opacity: 0; /* Make the X button invisible initially */
  transition: opacity 0.3s ease;
  cursor: pointer;
  font-size: 16px;
  color: #ccc; /* Light color for the X button */
}
.todo-text {
  flex-grow: 1;
  margin-right: 10px; /* Add space between the text and the X button */
}
input[type="checkbox"] {
  appearance: none;
  margin: 0;
}

/* Adjusting the design to align the todo input box with the text field and button */

.todo-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensure alignment of items vertically */
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
  0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.todo-input {
  flex-grow: 1;
  margin-right: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.todo-button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}
